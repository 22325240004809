import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "nprogress/nprogress.css";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";
import App from "./App";
import * as buffer from "buffer";
import "@rainbow-me/rainbowkit/styles.css";
import { defineChain } from "viem";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(utc);
// @ts-ignore
window.Buffer = buffer.Buffer;
//@ts-ignore
// const bscTestnet = defineChain({
//   id: 97,
//   name: "Binance Smart Chain Testnet",
//   network: "bsc-testnet",
//   nativeCurrency: {
//     decimals: 18,
//     name: "BNB",
//     symbol: "tBNB",
//   },
//   rpcUrls: {
//     default: { http: ["https://rpc.ankr.com/bsc_testnet_chapel"] },
//     public: { http: ["https://rpc.ankr.com/bsc_testnet_chapel"] },
//   },
//   blockExplorers: {
//     etherscan: { name: "BscScan", url: "https://testnet.bscscan.com" },
//     default: { name: "BscScan", url: "https://testnet.bscscan.com" },
//   },
//   contracts: {
//     multicall3: {
//       address: "0xca11bde05977b3631167028862be2a173976ca11",
//       blockCreated: 17422483,
//     },
//   },
//   testnet: true,
// });

//@ts-ignore
const bscTestnet = defineChain({
  id: 4200,
  name: "Merlin Mainnet",
  network: "Merlin Mainnet",
  nativeCurrency: {
    decimals: 4200,
    name: "BTC",
    symbol: "BTC",
  },
  rpcUrls: {
    default: { http: ["https://rpc.merlinchain.io"] },
    public: { http: ["https://rpc.merlinchain.io"] },
  },
  blockExplorers: {
    etherscan: { name: "merlinchain", url: "https://scan.merlinchain.io" },
    default: { name: "merlinchain", url: "https://scan.merlinchain.io" },
  },
  testnet: false,
});

const { chains, publicClient } = configureChains(
  [bscTestnet],
  [publicProvider()]
);
const { connectors } = getDefaultWallets({
  appName: "MerlinPrincess",
  projectId: "94faf1da02b2f1d727964d4272fbd06e",
  //@ts-ignore
  chains,
});
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
const element = document.getElementById("root");
const root = createRoot(element!);

root.render(
  <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      {/* @ts-ignore */}
      <RainbowKitProvider chains={chains} id="rainbow">
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </BrowserRouter>
);
